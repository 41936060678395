<template>
  <Anonymous>
    <b-overlay :show="showOverlay" rounded="sm">

      <div class="form">
        <div class="row">
          <div class="col logo">
            <BigLogo />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h3 class="logo-subheader">
              Smart. Simplified. Streamlined.
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <h3 class="login-form-header">
              Welcome. Please sign in.
            </h3>
            <b-form @submit.prevent="onSubmit" class="mt-4 login-form">
              <div role="group" class="form-group-input">
                <b-form-input
                    id="login-email"
                    v-model="form.email"
                    autocomplete="username"
                    type="text"
                    :state="validateState('email')"
                    placeholder="Your email"
                ></b-form-input>
                  <b-form-invalid-feedback id="input-email-feedback">
                      <div v-if="!$v.form.email.email">
                          The email is not valid
                      </div>
                  </b-form-invalid-feedback>
              </div>
              <div role="group" class="form-group-input mt-4">
                <b-form-input
                    id="login-password"
                    v-model="form.password"
                    autocomplete="current-password"
                    :type=inputPasswordType
                    placeholder="Your password"
                    :state="validateState('password')"
                ></b-form-input>
                <b-form-invalid-feedback id="input-password-feedback" class="mt-1">
                    <div v-if="!$v.form.password.minLength">
                        Mast be at least 8 characters
                    </div>
                    <div v-else>
                        Email address or password is incorrect
                    </div>
                </b-form-invalid-feedback>
                <div class="icon">
                  <b-icon icon="eye" font-scale="1.5" @click="togglePassword"></b-icon>
                </div>
              </div>
              <div class="mt-4 d-flex">
                <div class="flex-fill">
                  <b-button
                      type="submit"
                      variant="primary"
                      :disabled="disabled">Login now >
                  </b-button>
                </div>
                <div class="flex-fill d-flex align-items-center justify-content-end">
                  <router-link to="/password-recovery" class="forgot-password-link">Forgot Password?</router-link>
                </div>
              </div>
            </b-form>

          </div>
        </div>
      </div>
    </b-overlay>

  </Anonymous>
</template>

<script>
import Anonymous from "../layouts/Anonymous";
import {validationMixin} from 'vuelidate';
import {required, maxLength, minLength, email} from 'vuelidate/lib/validators';
import {mapActions, mapGetters} from "vuex";
import BigLogo from "../components/BigLogo";

export default {
  name: "Login",
  components: {Anonymous, BigLogo, },
  mixins: [validationMixin],
  data() {
    return {
        authError: false,
      form: {
        email: '',
        password: '',
      },
      showOverlay: false,
      showPassword: false,
      effort: 0
    }
  },
  mounted() {
    this.clearErrors();
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "redirect"]),
    ...mapGetters("errors", ['hasErrors']),
    inputPasswordType: function () {
      if (this.showPassword) {
        return 'text';
      }
      return 'password';
    },
    disabled: function () {
      return this.$v.form.$anyError;
    },
    redirectTo() {
      if (this.redirect) {
        return this.redirect;
      }
      if (this.$route.query.redirectTo === undefined)
        return false;

      let result = this.$route.query.redirectTo;

      for (const key in this.$route.query) {
        if (key !== 'redirectTo') {
          result = result + '&' + key + '=' + this.$route.query[key];
        }
      }

      return result;
    },
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(8)
      }
    }
  },
  methods: {
    ...mapActions("auth", ["login", "clearRedirect"]),
    ...mapActions('errors', ['clearErrors']),
      onSubmit() {
          this.$v.form.$touch();
          if (this.$v.form.$anyError) {
              return;
          }
          this.effort++;
          this.showOverlay = true;
          this.login(this.form).then(() => {

              if (this.isAuthenticated) {
                  this.effort = 0;

              } else {
                  if (this.effort > 2) {
                      this.$router.push('password-recovery');
                  }
              }
              this.showOverlay = false;
          });

      },
    togglePassword() {
      if (this.showPassword) {
        this.showPassword = false;
      } else {
        this.showPassword = true;
      }
    },
    validateState(name) {
      if (this.hasErrors) {
        return false;
      }
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },

  },
  watch: {
    'form.email': function () {
      this.clearErrors();
      this.$v.form.email.$touch();
    },
    'form.password': function () {
      this.clearErrors();
      this.$v.form.password.$touch();
    },

  }
}
</script>

<style scoped>

</style>